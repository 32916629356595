var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#fff" } },
    [
      _c(
        "Title",
        { attrs: { name: "收货地址", operate: true } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.toAddAddress },
            },
            [_vm._v("添加地址")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                "header-cell-style": {
                  background: "#f5f5f5",
                  "font-weight": 500,
                  color: "#666",
                  padding: "12 5px",
                },
                data: _vm.list,
                size: "small",
                "element-loading-text": "正在查询中。。。",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "100px",
                  label: "收货人名称",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "100px",
                  label: "手机号码",
                  prop: "mobile",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "300px",
                  label: "地址",
                  prop: "detailedAddress",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "220",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleModify(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.total
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      total: _vm.total,
                      "page-size": _vm.listQuery.limit,
                    },
                    on: { "current-change": _vm.handleChangePage },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }