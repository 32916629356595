<template>
<div style="background:#fff">
  <Title name='收货地址' :operate="true">
    <el-button type="primary" size="small" @click="toAddAddress">添加地址</el-button>
  </Title>
  <div class="app-container">
     
    <!-- 查询和其他操作 -->
    <!--<div class="filter-container">-->
    <!--  <el-input v-model="listQuery.userId" clearable size="small" class="filter-item" style="width: 200px;" placeholder="请输入用户ID"/>-->
    <!--  <el-input v-model="listQuery.name" clearable size="small" class="filter-item" style="width: 200px;" placeholder="请输入收货人名称"/>-->
    <!--  <el-button class="filter-item" type="primary" size="mini" icon="el-icon-search" @click="handleFilter">查找</el-button>-->
    <!--  <el-button :loading="downloadLoading" size="mini" class="filter-item" type="warning" icon="el-icon-download" @click="handleDownload">导出</el-button>-->
    <!--</div>-->

    <!-- 查询结果 -->
    <el-table 
      :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px'}"
      v-loading="listLoading" 
      :data="list" size="small" 
      element-loading-text="正在查询中。。。" >
      <!--<el-table-column align="center" width="100px" label="地址ID" prop="id" sortable/>-->

      <!--<el-table-column align="center" min-width="100px" label="用户ID" prop="userId"/>-->

      <el-table-column align="center" min-width="100px" label="收货人名称" prop="name"/>

      <el-table-column align="center" min-width="100px" label="手机号码" prop="mobile"/>

      <el-table-column align="center" min-width="300px" label="地址" prop="detailedAddress">
      </el-table-column>

      <!--<el-table-column align="center" min-width="80px" label="默认" prop="isDefault">-->
      <!--  <template slot-scope="scope">-->
      <!--    {{ scope.row.isDefault ? '是' : '否' }}-->
      <!--  </template>-->
      <!--</el-table-column>-->
      <el-table-column align="center" label="操作" width="220" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="handleModify(scope.row)">编辑</el-button>
          <el-button size="small" type="text" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination" v-if="total">
      <el-pagination
              background layout="prev, pager, next"
              :total='total'
              :page-size='listQuery.limit'
              @current-change="handleChangePage"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import Title from '../../component/Title';
// import { listAddress } from '@/api/business/user'
// import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
import {userAddressList,deleteAddress} from "../../api";

export default {
  name: 'UserAddress',
  // components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        // name: undefined,
        // userId: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    toAddAddress() {
      this.$router.push({path:"/User/AddOrModify"});
    },
    getList() {
      this.listLoading = true
      userAddressList(this.listQuery).then(res => {
        if(res.errno===0){
          this.list = res.data.items
          this.total = res.data.total
          this.listLoading = false
        }else{
          this.$notify.error({
            title:"错误",
            message:res.errmsg
          })
        }
      }).catch(() => {
        this.list = []
        this.total = 0
        this.listLoading = false
      })
    },
    handleChangePage() {
      this.listQuery.page = 1
      this.getList()
    },
    handleDel(row){
      this.$confirm("确定删除该条地址吗？", "", {
        type: 'warning'
      }).then(() =>{
        deleteAddress(row).then(res=>{
          if(res.errno===0){
            this.$notify({
              title:"提示",
              message:"删除"+res.errmsg,
              type: 'success'
            })
            this.getList();
          }else{
            this.$notify.error({
            title:"错误",
            message:res.errmsg
          })}
        })
      }).catch(()=>{
        console.log("已取消")
      })
    },
    handleModify(row){
      this.$router.push({path:"/User/AddOrModify",query:{id:row.id}})
    },
    handleDownload() {
      // this.downloadLoading = true
      // import('@/vendor/Export2Excel').then(excel => {
      //   const tHeader = ['地址ID', '用户ID', '收获人', '手机号', '省', '市', '区', '地址', '是否默认']
      //   const filterVal = ['id', 'userId', 'name', 'mobile', 'province', 'city', 'area', 'address', 'isDefault']
      //   excel.export_json_to_excel2(tHeader, this.list, filterVal, '用户地址信息')
      //   this.downloadLoading = false
      // })
    }
  },
  components: {
    Title
  }
}
</script>
<style>
  .pagination {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }

  .app-container {
    /* background: #fff; */
    padding: 10px;
  }
</style>
